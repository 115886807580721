import React, { useEffect, useState } from "react";
import { AIRTICKET_CONTROLLER_API, baseUrl } from "../../contants/Endpoints";
import Paginations from "../../pages/admin/Paginations/Paginations";
import { authAxios } from "../../utils/axiosWrapper";
import { optionsTime } from "./OtpQuery";

export default function TicketStatusCheck() {
  const [ticketList, setTicketList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [totalAirport, setTotalAirport] = useState(0);
  let pageSize = 10;
  const handleGetTicketStatus = () => {
    authAxios
      .get(
        `${baseUrl}${AIRTICKET_CONTROLLER_API.FLIGHT_TICKET_STATUS_GET_API}?pageNumber=${currentPage}&pageSize=${pageSize}&queryString=${searchText}&requestId=searchStatus`
      )
      .then((res) => {
        setTicketList(res?.data);
        setTotalPage(res?.data?.totalPages);
        setTotalAirport(res?.data?.numberOfEntries);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const delay = setTimeout(() => {
      handleGetTicketStatus();
    }, 1000); // Delay of 2000 milliseconds (2 seconds)

    return () => clearTimeout(delay);
  }, [currentPage, searchText]);
  console.log("ticketList", ticketList);
  return (
    <div className="mt-[60px]">
      <div className="max-w-[540px] flex justify-between items-center gap-[20px] h-full">
        <div className="w-full">
          <p className="text-[18px]">Search </p>
          <input
            placeholder="Search"
            className="w-full px-[12px] border border-btn-border rounded-[12px] py-[4px]"
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <p className="whitespace-nowrap">Total Ticket try : {totalAirport}</p>
      </div>
      <table className="fl-table mt-[20px]">
        <thead className="sticky top-[88px]">
          <tr>
            <th>
              <span>Ticket</span>
            </th>
            <th>Booking Status</th>
            <th>Time</th>
            <th>Email</th>
            <th>Invoice Id</th>
            <th>Order Status</th>
            <th>Phone </th>
            <th>Amount</th>
            <th>username</th>
          </tr>
        </thead>
        <tbody>
          {ticketList?.entries?.map((ticketStatus, index) => (
            <tr key={index}>
              <td>
                <span className="flex flex-col justify-start items-start">
                  <span className="text-[10px]">
                    #{ticketStatus?.sales_order_id}
                  </span>
                  <span className="whitespace-pre-line ">
                    {ticketStatus?.booking_reference}
                  </span>
                </span>
              </td>

              <td className={``}>{ticketStatus?.booking_status}</td>
              <td className={``}>
                {new Date(ticketStatus?.booking_time).toLocaleString(
                  "en-US",
                  optionsTime
                )}
              </td>
              <td className={``}>{ticketStatus?.email}</td>
              <td className={``}>{ticketStatus?.invoice_id}</td>
              <td className={``}>{ticketStatus?.order_change_status}</td>
              <td className={``}>{ticketStatus?.phone}</td>

              <td className={``}>
                {ticketStatus?.total_customer_payable_amount}
              </td>
              <td className={``}>{ticketStatus?.username}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="my-10">
        <Paginations
          currentPage={currentPage}
          totalCount={totalPage}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}
